import moment from 'moment';
import * as intl from 'react-intl-universal';
import { DATE_FORMAT_ISO, DEFAULT_ORDER_METADATA_PACKAGE_CODE } from '../constants';
import { store } from '../store';
import { orderAction } from '../store/order/order.actions';
import * as orderConstants from '../store/order/order.constants';
import { NUTRITION_PREFERENCE_CODE, ORDER_METADATA_HEADER_CODE } from '../store/order/order.constants';
import { CancelledOrderDto, CancelledOrdersSearchFilter } from '../types/cancelled-order-dto.types';
import { AddDeliveryScheduleBagDto, DeliverableDateInfo, DeliveryScheduleDayDto, DeliveryScheduleDaysProjectionDto, MoveDeliveryScheduleBagsDto, ShiftBagsResultDto, SkipDaysDto, SkipDeliveryScheduleDaysForOrdersDto } from '../types/delivery-dto.types';
import { DaysOfWeek, MealBalancingStatus, OrderPlacementType, OrderType } from '../types/fff.enums';
import { MacrosDto } from '../types/macro-dto.types';
import { DailyBagDto } from '../types/meal-management.types';
import {
  CancelOrderDetailsDto, CancelOrderDto, DeliveryInfoDto, ExclusionsDto, FlexiAnalyticsSummaryDto, FlexiOrderDto, FlexiOrdersSearchFilter,
  MealPlanDto, OrderBasicDetail, OrderCreateResultDto, OrderDetailsDto, OrderFreeBagRequestDto, OrderPayment, OrderPricingSummaryDto, OrderPricingSummaryRequestDto, OrderRequestDto, OrderSpecDto,
  OrderSpecSearchFilter, OrderStagingMacrosDto, OrderStatus, MaximumApplicableTokensRequestDto, OrderUpdateResultDto, PackageMetadataFilter, PauseOrderDto, PaymentResultDto,
  QuickReOrderConfigDto, SpotPaymentResult, TrialOrderSearchResultsDto, TrialOrdersSearchFilter, TrialOrdersSummaryDto, CurrentPackageSummary, CancelOrderRequestDto
} from "../types/order-dto.types";
import {
  CalorieMatrixDto, ExtrasPricingMatrixDto, GuestOrderListDto, MealExtrasUnitPriceDto, MetadataDictionary, MetadataMapping, MetadataWithMappings, Order,
  OrderChargeSummary, OrderGoalNutritionPreferenceMapping, OrderListRequest, OrderMetadataItem, OrdersMealBalancingSummary,
  OrderStaging, PackageMetadata, PackagePriceDictionary, PackagePricingMatrixDto, WeeklyOrderListItemDto, WeeklyOrdersListRequest
} from "../types/order-store.types";
import { DateRangeSearchFilter, EntitySaveResult, SearchFilterBase, SearchResultsDto } from "../types/shared-dto.types";
import { PaymentContextDto, PaymentOption, PayPalAuthorizationRequestDto, PayPalOrderResponseDto, ResumeOrderDto } from './../types/order-dto.types';
import { apiService } from "./shared/api.service";
import { messagingService } from './shared/messaging.service';
import { sessionService } from './shared/session.service';
import { utilsService } from './shared/utils.service';
import { dateTimeUtils } from '../utils/datetime-formatter.utils';

export const orderService = {
  getGuestOrders,
  getStagedOrderById,
  getGoalsMetaData,
  getNutritionPreferences,
  getGoalNutritionPreferenceMappings,
  getActiveLevels,
  getWorkingHoursPerWeek,
  getExerciseTypes,
  getPackageTypes,
  getExerciseHabits,
  stageOrder,
  getMetadataKeyValues,
  getAllOrderFormMetadata,
  getAllMetadata,
  getMetadataMappings,
  getFilteredMetadataForOrderForm,
  getFilteredMetadata,
  getIsolatedItemsToFlush,
  calculateMacros,
  loadPackagePrices,
  createOrder,
  getOrderChargeSummary,
  getStartDate,
  getCurrentOrder,
  getDeliveryScheduleDaysForOrder,
  getDeliveryInfo,
  moveDeliveryScheduleBags,
  addDeliveryScheduleBags,
  skipDelivery,
  skipDeliveryForOrders,
  getMealsMetadata,
  changeDeliveryPattern,
  pauseOrder,
  getPauseOrderDetails,
  resumeOrder,
  getResumeEligibleDate,
  editMealPlan,
  editMacros,
  getCalorieMatrixData,
  getExclusions,
  editExclusions,
  isValidOrder,
  makePayment,
  getCancelOrderDetails,
  cancelOrder,
  createCancellationRequest,
  getCancelledOrders,
  downloadCancelledOrdersCsv,
  getOrderStatusDescription,
  getPackageCount,
  getCurrentOrderForCurrentUser,
  getPendingOrderForCurrentUser,
  toggleOrderForm,
  loadOrderStaging,
  reOrder,
  getReOrderDetails,
  getOrderSpecList,
  getOrderSpec,
  stageExistingOrder,
  getNextDeliverableDate,
  clearStagedOrders,
  getStartDateByDeliverability,
  addFreeBagToOrder,
  getMealBalancingStatus,
  getCssClassForBalancingStatus,
  setUpPayPalAuthorization,
  authorizePayPalOrder,
  getPackagePricingData,
  getExtrasUnitPricingData,
  getOrderMealBalancingSummary,
  getColorCodeForBalancingStatus,
  getPackageMetadata,
  getFilteredPackageMetadata,
  triggerRebalancingForAllOrders,
  getGuestOrdersCsv,
  changeOrderStartDate,
  getMinOrderStartDate,
  getOrder,
  getOrderSummary,
  modifyOrder,
  showOrderEditWizard,
  calculateUpdatedMacros,
  getWeeklyOrders,
  makeWeeklyPayment,
  getDefaultPackage,
  getPromotionalPackageTypes,
  getFilteredPackagesForType,
  getTrialOrders,
  getTrialOrdersSummary,
  downloadTrialOrdersCsv,
  deleteStagingOrder,
  downloadCancelledRollingSubscriptionUsersCsv,
  calculateProvidableMacros,
  getOrderStagingById,
  getPricingMatrix,
  getPricingMatrixById,
  updatePricingMatrix,
  getFlexiOrders,
  fixStaledMealBalancing,
  downloadFlexiOrdersCsv,
  getFlexiAnalyticsSummary,
  downloadFlexiPaymentsCsv,
  downloadFlexiOrderPauseLogsCsv,
  getExtrasPricingMatrix,
  getExtrasPricingMatrixById,
  updateExtrasPricingMatrix,
  getOrderPlacementTypeOptions,
  syncOrderPlacementTypes,
  getOrderPricingSummary,
  getMaximumApplicableTokens,
  getTooltipTextForNutritionPreference,
  getExtras,
  updateCustomOrderStatus,
  getApplicableFFFPackages,
  getOrderDetailedInfo
}

function isValidOrder(status?: OrderStatus) {
  !status && (status = OrderStatus.Started);
  return status !== OrderStatus.Paused && status !== OrderStatus.Cancelled && status !== OrderStatus.Expired;
}

function getGoalsMetaData() {
  return apiService.get<OrderMetadataItem[]>('ordermetadata', 'ordergoals');
}

function getNutritionPreferences() {
  return apiService.get<OrderMetadataItem[]>('ordermetadata', 'nutritionpreferences')
}

function getGoalNutritionPreferenceMappings() {
  return apiService.get<OrderGoalNutritionPreferenceMapping[]>('ordermetadata', 'ordergoalnutritionpreferencemappings')
}

function getActiveLevels() {
  return apiService.get<OrderMetadataItem[]>('ordermetadata', 'activelevels');
}

function getWorkingHoursPerWeek() {
  return apiService.get<OrderMetadataItem[]>('ordermetadata', 'workinghoursperweek');
}

function getExerciseTypes() {
  return apiService.get<OrderMetadataItem[]>('ordermetadata', 'exercisetypes');
}

function getExtras() {
  return apiService.get<MetadataDictionary>('ordermetadata', 'extras');
}

function getPackageTypes(paymentOption: PaymentOption, excludeNonRenewables: boolean = false, adminOnly: boolean = false) {
  return apiService.get<OrderMetadataItem[]>('ordermetadata', 'packagetypes', undefined, { paymentOption, excludeNonRenewables, adminOnly });
}

function getPackageMetadata() {
  return apiService.get<PackageMetadata[]>('ordermetadata', 'packagemetadata', undefined);
}

function getExerciseHabits() {
  return apiService.get<OrderMetadataItem[]>('ordermetadata', 'exercisehabits');
}

function getPricingMatrix(filter: SearchFilterBase) {
  return apiService.get<SearchResultsDto<PackagePricingMatrixDto>>('ordermetadata', 'PricingMatrix', [], filter)
}

function getPricingMatrixById(noOfDays?: number) {
  return apiService.get<PackagePricingMatrixDto>('ordermetadata', `PricingMatrix/${noOfDays}`)
}

function updatePricingMatrix(noOfDays: number, dto: PackagePricingMatrixDto) {
  return apiService.put<EntitySaveResult>('ordermetadata', `PricingMatrix/${noOfDays}`, dto);
}

function getExtrasPricingMatrix(filter: SearchFilterBase) {
  return apiService.get<SearchResultsDto<ExtrasPricingMatrixDto>>('ordermetadata', 'ExtrasPricingMatrix', [], filter)
}

function getExtrasPricingMatrixById(code: string) {
  return apiService.get<ExtrasPricingMatrixDto>('ordermetadata', `ExtrasPricingMatrix/${code}`)
}

function updateExtrasPricingMatrix(code: string, dto: ExtrasPricingMatrixDto) {
  return apiService.put<EntitySaveResult>('ordermetadata', `ExtrasPricingMatrix/${code}`, dto);
}

function getMetadataKeyValues(headerCodes?: string[]) {
  return apiService.get<MetadataDictionary>('ordermetadata', 'KeyValues', [], { headerCodes });
}

function getAllOrderFormMetadata(excludeNonRenewables: boolean = false, includeAdminOnly: boolean = false) {
  const headerCodes = [
    ORDER_METADATA_HEADER_CODE.ORDER_GOAL, ORDER_METADATA_HEADER_CODE.NUTRITION_PREFERENCE,
    ORDER_METADATA_HEADER_CODE.ACTIVE_LEVEL, ORDER_METADATA_HEADER_CODE.WORKOUT_HOURS_PER_WEEK,
    ORDER_METADATA_HEADER_CODE.MATERNITY_STATUS, ORDER_METADATA_HEADER_CODE.PREGNANCY_STATUS, ORDER_METADATA_HEADER_CODE.BREASTFEEDING_STATUS,
    ORDER_METADATA_HEADER_CODE.MEAL_COUNT, ORDER_METADATA_HEADER_CODE.MISCELLANEOUS,
    ORDER_METADATA_HEADER_CODE.MEAL_EXTRAS, ORDER_METADATA_HEADER_CODE.CUSTOM_MEAL_OPTION, ORDER_METADATA_HEADER_CODE.JUICES,
    ORDER_METADATA_HEADER_CODE.BOOSTERS, ORDER_METADATA_HEADER_CODE.SNACKS, ORDER_METADATA_HEADER_CODE.SHAKES,
    ORDER_METADATA_HEADER_CODE.PACKAGE_TYPE, ORDER_METADATA_HEADER_CODE.PAYMENT_OPTION,
  ];

  return apiService.get<MetadataWithMappings>('ordermetadata', '', [], { excludeNonRenewables, includeAdminOnly, headerCodes });
}

function getAllMetadata(excludeNonRenewables: boolean = false, includeAdminOnly: boolean = false, headerCodes: string[] = []) {
  return apiService.get<MetadataWithMappings>('ordermetadata', '', [], { excludeNonRenewables, includeAdminOnly, headerCodes });
}

function getMetadataMappings() {
  return apiService.get<MetadataMapping[]>('ordermetadata', 'mappings');
}

function getCalorieMatrixData() {
  return apiService.get<CalorieMatrixDto[]>('ordermetadata', 'caloriematrix');
}

function getPackagePricingData() {
  return apiService.get<PackagePricingMatrixDto[]>('ordermetadata', 'PackagePricingMatrixGroups');
}

function getExtrasUnitPricingData() {
  return apiService.get<MealExtrasUnitPriceDto[]>('ordermetadata', 'ExtrasUnitPrices');
}

function getMealsMetadata(nutritionPreferenceId: string) {
  return apiService.get<MetadataDictionary>('ordermetadata', 'MealConfiguration', undefined, { nutritionPreferenceId });
}

function getPromotionalPackageTypes() {
  return apiService.get<OrderMetadataItem[]>('ordermetadata', 'promoPackageTypes');
}

function getCurrentOrder(userId: string) {
  return apiService.get<Order>('orders', 'current', [userId])
    .then((result: Order) => {
      result && result.birthDay && (result.birthDay = new Date(result.birthDay));
      return result;
    })
}

function getDeliveryScheduleDaysForOrder(orderId: string) {
  return apiService.get<DeliveryScheduleDaysProjectionDto>('orders', 'deliveryschedule', [orderId])
    .then((result: DeliveryScheduleDaysProjectionDto) => {
      if (result && result.activeDays) {
        result.activeDays = result.activeDays.map((day: DeliveryScheduleDayDto) => {
          day.day = day.day && new Date(day.day);
          return day;
        });
      }
      result && result.pendingDays && (result.pendingDays = result.pendingDays.map((day: DeliveryScheduleDayDto) => {
        day.day = day.day && new Date(day.day);
        return day;
      }));
      return result;
    });
}

function getDeliveryInfo(id: string) {
  return apiService.get<DeliveryInfoDto>('orders', 'deliveryInfo', [id]);
}

function stageOrder(stagedOrder: OrderStaging, showGlobalSpinner: boolean = true) {
  let firstDeliveryDate = stagedOrder.firstDeliveryDate && new Date(stagedOrder.firstDeliveryDate);
  return apiService.post<EntitySaveResult>('orderstaging', 'stage', {
    ...stagedOrder,
    firstDeliveryDate: firstDeliveryDate && utilsService.formatDate(firstDeliveryDate),

    deliveryScheduleStaging: stagedOrder.deliveryScheduleStaging && stagedOrder.deliveryScheduleStaging.startDate && {
      ...stagedOrder.deliveryScheduleStaging,
      startDate: utilsService.formatDate(stagedOrder.deliveryScheduleStaging.startDate)
    }
  }, undefined, undefined, showGlobalSpinner);
}

function getStartDate(isNewOrder: boolean = false) {
  return apiService.get<Date>('orders', 'startDate', [], { isNewOrder })
    .then((date: Date) => new Date(date));
}

function getStartDateByDeliverability(
  postcodeId: string, isTimedDelivery: boolean, isNewOrder: boolean = false,
  paymentOption: PaymentOption = PaymentOption.Upfront, effectiveDate?: Date,
  selectedDeliveryDaysOfWeek?: DaysOfWeek[]
) {
  return apiService.get<DeliverableDateInfo>('orders', 'startDate/bydeliverability/info', [],
    {
      postcodeId, isTimedDelivery,
      effectiveDate: effectiveDate && effectiveDate.toDateString(),
      isNewOrder, paymentOption, selectedDeliveryDaysOfWeek
    })
    .then((res) => ({
      date: new Date(res.date),
      availableDaysOfWeek: res.availableDaysOfWeek
    } as DeliverableDateInfo));
}

function pauseOrder(orderId: string, pauseDate?: Date, pauseReasonId?: string, pauseReasonDetails?: string) {
  return apiService.patch('orders', 'pause', null, [], {
    orderId,
    pauseDate: pauseDate && pauseDate.toDateString(),
    pauseReasonId,
    pauseReasonDetails
  }, true);
}

function resumeOrder(request: ResumeOrderDto) {
  return apiService.patch<OrderUpdateResultDto>('orders', 'resume', {
    ...request,
    resumeDate: request.resumeDate && dateTimeUtils.toLocalIsoDateFormatString(request.resumeDate)
  },
    undefined, undefined, true);
}

function getPauseOrderDetails(orderId: string) {
  return apiService.get<PauseOrderDto>('orders', 'pause', [orderId], undefined, true);
}

function getResumeEligibleDate(orderId: string) {
  return apiService.get<Date>('orders', 'resume', [orderId])
    .then((date: Date) => new Date(date));
}

function moveDeliveryScheduleBags(moveBagsDto: MoveDeliveryScheduleBagsDto) {
  return apiService.post<ShiftBagsResultDto>('orders', 'deliveryScheduleDays/move',
    { ...moveBagsDto, fromDate: moveBagsDto.fromDate.toDateString(), toDate: moveBagsDto.toDate.toDateString() }, undefined, null, true);
}

function addDeliveryScheduleBags(addBagsDto: AddDeliveryScheduleBagDto) {
  return apiService.post<ShiftBagsResultDto>('orders', 'deliveryScheduleDays/add',
    { ...addBagsDto, toDate: utilsService.formatDate(addBagsDto.toDate) }, undefined, null, true);
}

function skipDelivery(skipDeliveryDto: SkipDaysDto) {
  return apiService.post<OrderUpdateResultDto>('orders', 'deliveryScheduleDays/skip', {
    ...skipDeliveryDto,
    skipDate: skipDeliveryDto.skipDate.toDateString(),
    toDate: skipDeliveryDto.toDate && utilsService.formatDate(skipDeliveryDto.toDate)
  }, undefined, null, true);
}

function skipDeliveryForOrders(dto: SkipDeliveryScheduleDaysForOrdersDto) {
  return apiService.post('orders', 'deliveryScheduleDays/skip/allOrders', {
    ...dto,
    fromDate: moment(dto.fromDate).format(DATE_FORMAT_ISO),
    toDate: moment(dto.toDate).format(DATE_FORMAT_ISO)
  });
}

function changeDeliveryPattern(orderId: string, deliveryPattern: string) {
  return apiService.post<PaymentResultDto>('orders', 'deliveryScheduleDays/changeDeliveryPattern', { orderId, deliveryPattern }, undefined, null, true);
}

function loadPackagePrices(mealCount: number, extrasIds: string[], packageDays: string[], paymentOption: PaymentOption = PaymentOption.Weekly, orderType: OrderType = OrderType.FFF) {
  var queryParams = {
    mealCount,
    extrasIds: extrasIds.join(';'),
    packages: packageDays.join(';'),
    paymentOption,
    orderType
  }

  return apiService.get<PackagePriceDictionary>('orderstaging', 'getpackageprices', undefined, queryParams);
}

function calculateMacros(email: string) {
  return apiService.put<OrderStagingMacrosDto>('orderstaging', 'macros', { email });
}

function calculateProvidableMacros(regularCalories: number, derivedCalories: number, macros: MacrosDto) {

  const requiredCaloriesByFFFMeals = macros.dailyCalories! - regularCalories;
  const providableCaloriesByFFFMelas = Math.min(requiredCaloriesByFFFMeals, derivedCalories);

  let supplyRatio = providableCaloriesByFFFMelas / macros.dailyCalories!;
  supplyRatio = supplyRatio > 1 ? 1 : supplyRatio;

  macros.dailyCalories = Math.round(macros.dailyCalories! * supplyRatio);
  macros.dailyCarbsInGrams = Math.round(macros.dailyCarbsInGrams! * supplyRatio);
  macros.dailyProteinInGrams = Math.round(macros.dailyProteinInGrams! * supplyRatio);
  macros.dailyFatInGrams = Math.round(macros.dailyFatInGrams! * supplyRatio);

  return macros;
}

function createOrder(orderRequest: OrderRequestDto) {
  return apiService.post<OrderCreateResultDto>('orders', '', orderRequest, undefined, undefined, true);
}

function getIsolatedItemsToFlush(): MetadataDictionary {
  return {
    'JUICES': [],
    'SHAKES': [],
    'BOOSTERS': [],
    'SNACKS': [],
    'MISCELLANEOUS': [],
    'NUTRITION_PREFERENCE_VARIANT': []
  }
}

function getFilteredMetadataForOrderForm(allMetadata: MetadataDictionary, mappings: MetadataMapping[],
  mappedFromHeader: string = '', mappedFromFieldId: string = '') {

  let filteredData: MetadataDictionary = {};

  if (mappedFromHeader == '') {
    //Get all the fields that are not bounded to a another field
    let metadataKeys = Object.keys(allMetadata);
    let fieldsBoundToMapping = Array.from(new Set(mappings.map((m) => m.field2Header)));
    let fieldsToAdd = metadataKeys.filter(k => fieldsBoundToMapping.indexOf(k) < 0);

    fieldsToAdd.forEach(key => {
      filteredData = { ...filteredData, [key]: allMetadata[key] }
    });
  }
  else {
    filteredData = getFilteredMetadata(allMetadata, mappings, mappedFromHeader, mappedFromFieldId);
  }

  tweakNutritionPreferences(filteredData);

  return filteredData;
}

function getFilteredMetadata(allMetadata: MetadataDictionary, mappings: MetadataMapping[],
  mappedFromHeader: string = '', mappedFromFieldId: string = '') {

  let filteredData: MetadataDictionary = {};

  //Get all the fields that are bounded to a another field
  var narrowedMappings = mappings.filter(m => m.field1Header == mappedFromHeader && m.field1Id == mappedFromFieldId);
  let fieldsToAdd = Array.from(new Set(narrowedMappings.map((m) => m.field2Header)))

  fieldsToAdd.forEach(key => {
    var mappedIds = narrowedMappings.filter(m => m.field2Header == key).map(m => m.field2Id);
    var relevantMetaData = allMetadata[key].filter(m => m.id && mappedIds.indexOf(m.id) >= 0);

    filteredData = { ...filteredData, [key]: relevantMetaData }
  });

  return filteredData;
}

function getFilteredPackageMetadata(allMetadata: MetadataDictionary, filterMetadata: MetadataDictionary, packageMetadata: PackageMetadata[], filter: PackageMetadataFilter) {
  let filteredData: MetadataDictionary = {};

  const orderPackageMetadata = allMetadata[orderConstants.ORDER_METADATA_HEADER_CODE.PACKAGE_TYPE];
  const filteredOrderPackageMetadataIds = packageMetadata.map(p => {
    if (filter.promotional && p.promotional) {
      return p.packageOrderMetadataId;
    }
    else if (!p.promotional) {
      if (filter.weeklyPaymentEnabled && !filter.autoRenewable && p.weeklyPaymentEnabled)
        return p.packageOrderMetadataId;
      else if (filter.autoRenewable && !filter.weeklyPaymentEnabled && p.autoRenewable)
        return p.packageOrderMetadataId
      else if (filter.autoRenewable && filter.weeklyPaymentEnabled && p.autoRenewable && p.weeklyPaymentEnabled)
        return p.packageOrderMetadataId
      else if (!filter.weeklyPaymentEnabled && !filter.autoRenewable)
        return p.packageOrderMetadataId
    }
  });

  const filteredOrderPackageMetadata = orderPackageMetadata.filter(p => filteredOrderPackageMetadataIds.includes(p.id));
  filteredData = {
    ...filterMetadata,
    [orderConstants.ORDER_METADATA_HEADER_CODE.PACKAGE_TYPE]: filteredOrderPackageMetadata
  };

  return filteredData;
}

function getFilteredPackagesForType(packageMetadata: PackageMetadata[], packages: OrderMetadataItem[], orderType: OrderType) {
  const packageMetadataForType = packageMetadata.filter(p => p.promotional === (orderType === OrderType.Promotional)).map(p => p.packageOrderMetadataId);
  const filteredPackages = packages.filter(p => packageMetadataForType.includes(p.id));
  return filteredPackages;
}

function tweakNutritionPreferences(filteredMetaData: MetadataDictionary) {
  const nutritionPreferences: OrderMetadataItem[] = filteredMetaData[orderConstants.ORDER_METADATA_HEADER_CODE.NUTRITION_PREFERENCE];
  if (nutritionPreferences) {
    const preferenceCodesToExclude = orderConstants.NUTRITION_PREFERENCE_GROUP_MAPPING.PLANT_BASED;
    const plantBasedPreferences: OrderMetadataItem[] = [];
    preferenceCodesToExclude.forEach(preferenceCode => {
      const index = nutritionPreferences.findIndex(p => p.code == preferenceCode);
      if (index >= 0) {
        const deletedPreferences: OrderMetadataItem[] = nutritionPreferences.splice(index, 1);
        const deletedPreference = deletedPreferences[0];
        plantBasedPreferences.push(deletedPreference);
      }
    });

    if (plantBasedPreferences && plantBasedPreferences.length > 0)
      nutritionPreferences.splice(parseInt(orderConstants.PLANT_BASED_NUTRITION_PREFERENCE.sortOrder), 0, orderConstants.PLANT_BASED_NUTRITION_PREFERENCE);

    filteredMetaData[orderConstants.ORDER_METADATA_HEADER_CODE.NUTRITION_PREFERENCE] = nutritionPreferences;
    filteredMetaData['PLANT_BASED_NUTRITION_PREFERENCES'] = plantBasedPreferences;
  }
}

function getGuestOrders(request?: OrderListRequest) {
  return apiService.get<GuestOrderListDto[]>('orderstaging', 'ListGuestOrders', [],
    {
      ...request,
      toDate: request && request.toDate && utilsService.formatDate(request.toDate),
      fromDate: request && request.fromDate && utilsService.formatDate(request.fromDate),
    });
}

function getStagedOrderById(id: string) {
  return apiService.get<OrderStaging>('orderstaging', 'guestorder', [id]);
}

function getOrderChargeSummary(orderStagingId: string, discountCode?: string, paymentOption?: PaymentOption) {
  return apiService.get<OrderChargeSummary>('orderstaging', 'charge', [orderStagingId], { paymentOption, discountCode }, true)
    .then((chargeSummary: OrderChargeSummary) => {
      if (chargeSummary.weeklyPaymentInfo) {
        chargeSummary.weeklyPaymentInfo.nextWeeklyPaymentDate = new Date(chargeSummary.weeklyPaymentInfo.nextWeeklyPaymentDate);
        chargeSummary.weeklyPaymentInfo.weeklyPaymentEndDate = new Date(chargeSummary.weeklyPaymentInfo.weeklyPaymentEndDate);
      } else if (chargeSummary.flexiWeeklyPaymentInfo) {
        chargeSummary.flexiWeeklyPaymentInfo.nextWeeklyPaymentDate = new Date(chargeSummary.flexiWeeklyPaymentInfo.nextWeeklyPaymentDate);
      }

      return chargeSummary;
    });
}

function editMealPlan(order: MealPlanDto) {
  return apiService.patch<PaymentResultDto>('orders', 'mealplan', order, undefined, undefined, true);
}

function editMacros(macros: MacrosDto) {
  return apiService.patch<EntitySaveResult>('orders', 'macros', macros, undefined, undefined, true);
}

function editExclusions(orderId: string, exclusionIds?: string[]) {
  return apiService.patch<EntitySaveResult>('orders', 'exclusions', exclusionIds, [orderId], undefined, true);
}

function syncOrderPlacementTypes() {
  return apiService.patch<EntitySaveResult>('orders', 'SyncOrderPlacementTypes', '', [], undefined, true);
}

function getExclusions(orderId: string) {
  return apiService.get<ExclusionsDto[]>('orders', 'exclusions', [orderId]);
}

function makePayment(paymentDto: OrderPayment) {
  return apiService.post<SpotPaymentResult>('orders', 'payment', paymentDto, undefined, undefined, true);
}

function getCancelOrderDetails(orderId: string) {
  return apiService.get<CancelOrderDetailsDto>('orders', 'cancel', [orderId]);
}

function cancelOrder(cancleOrderDto: CancelOrderDto) {
  return apiService.post<CancelOrderDetailsDto>('orders', 'cancel', cancleOrderDto, undefined, undefined, true);
}

function createCancellationRequest(request: CancelOrderRequestDto) {
  return apiService.post<CancelOrderDetailsDto>('orders', request.orderId, request, ['cancellation-requests'], undefined, true);
}

function getCancelledOrders(filter: CancelledOrdersSearchFilter) {
  return apiService.get<SearchResultsDto<CancelledOrderDto>>('orders', 'cancel', [], {
    ...filter,
    fromDate: filter.fromDate && utilsService.formatDate(filter.fromDate),
    toDate: filter.toDate && utilsService.formatDate(filter.toDate)
  });
}

function downloadCancelledOrdersCsv(filter: CancelledOrdersSearchFilter) {
  apiService.download<any>('orders', 'cancel/csv', [], {
    ...filter,
    fromDate: filter.fromDate && utilsService.formatDate(filter.fromDate),
    toDate: filter.toDate && utilsService.formatDate(filter.toDate)
  }).catch((err) => {
    if (err.data.errorCode === 'ERR_REPORT_DATA_NOT_AVAILABLE')
      messagingService.showMessagePopup(intl.get(err.data.errorCode), intl.get(err.data.errorCode));
    else
      messagingService.notifyError(intl.get(err.data.errorCode));
  });
}

function downloadCancelledRollingSubscriptionUsersCsv(filter: DateRangeSearchFilter) {
  apiService.download<any>('orders', 'RollingSubscription/Csv', [], {
    ...filter,
    fromDate: filter.fromDate && utilsService.formatDate(filter.fromDate),
    toDate: filter.toDate && utilsService.formatDate(filter.toDate)
  }).catch((err) => {
    if (err.data.errorCode === 'ERR_REPORT_DATA_NOT_AVAILABLE')
      messagingService.showMessagePopup(intl.get(err.data.errorCode), intl.get(err.data.errorCode));
    else
      messagingService.notifyError(intl.get(err.data.errorCode));
  });
}

function getReOrderDetails(orderId: string, config?: QuickReOrderConfigDto) {
  return apiService.get<OrderDetailsDto>('orders', 'ReOrderDetails', [orderId], { ...config, startDate: config && config.startDate && config.startDate.toDateString() }, true)
    .then(result => {
      result.startDate && (result.startDate = new Date(result.startDate));
      return result;
    });
}

function triggerRebalancingForAllOrders() {
  return apiService.post<void>('orders', 'FlushAndReBalance/All', null, undefined, undefined, true);
}

function getOrderStatusDescription(orderStatus: number) {
  switch (orderStatus) {
    case OrderStatus.Started:
      return intl.get('TXT_STARTED');
    case OrderStatus.Paused:
      return intl.get('TXT_PAUSED');
    case OrderStatus.Resumed:
      return intl.get('TXT_RESUMED');
    case OrderStatus.Cancelled:
      return intl.get('TXT_CANCELLED');
    case OrderStatus.Completed:
      return intl.get('TXT_COMPLETED');
    case OrderStatus.Pending:
      return intl.get('TXT_PENDING');
    case OrderStatus.Expired:
      return intl.get('TXT_EXPIRED');
    case OrderStatus.Drafted:
      return intl.get('TXT_DRAFTED');
  }
}

function getOrderPlacementTypeOptions(filterBy: number) {

  switch (filterBy) {
    case OrderPlacementType.OrderForm: {
      return 'Re Order By Order Form';
    }
    case OrderPlacementType.QuickReorder: {
      return 'Quick Reorder';
    }
    case OrderPlacementType.AutoRenew: {
      return 'Auto Renew';
    }
  }
}

function getPackageCount(packageMetaData?: OrderMetadataItem) {
  let packageCount: number = 0;
  if (packageMetaData && packageMetaData.code) {
    let packageSplit = packageMetaData.code.split('_');
    if (packageSplit.length > 0) {
      packageCount = parseInt(packageSplit[0]);
    }
  }
  return packageCount;
}

function getCurrentOrderForCurrentUser() {
  const userId = sessionService.getUserId_obsolete() || '';
  return apiService.get<Order>('orders', 'current', [userId]);
}

function getPendingOrderForCurrentUser() {
  return apiService.get<Order>('orders', 'pending');
}

function toggleOrderForm() {
  store.dispatch({ type: orderConstants.TOGGLE_ORDER_FORM });
}

function loadOrderStaging(orderStaging: OrderStaging) {
  store.dispatch({ type: orderConstants.LOAD_ORDER_STAGING, orderStaging })
}

function modifyOrder(order: Order, paymentContext: PaymentContextDto) {
  return apiService.put<PaymentResultDto>('orders', undefined, order, undefined, paymentContext, true);
}

function reOrder(orderId: string, config?: QuickReOrderConfigDto, transactionId?: string) {
  return apiService.post<PaymentResultDto>('orders', 'reorder', config ?
    {
      packageId: config.packageId,
      startDate: config.startDate && utilsService.formatDate(config.startDate),
      discountCode: config.discountCode,
      selectedTokens: config.selectedTokens,
      transactionId: transactionId,
      paymentPlatform: config.paymentPlatform,
      paymentOption: config.paymentOption,
      allowPayment: config.allowPayment,
      paymentSessionId: config.paymentSessionId,
      platformPaymentId: config.platformPaymentId,
      orderPlacementType: OrderPlacementType.QuickReorder
    } : config, [orderId], undefined, true);
}

function getOrderSpecList(filter: OrderSpecSearchFilter) {
  return apiService.get<SearchResultsDto<OrderBasicDetail[]>>('orders', 'listOrders', [], filter, true);
}

function getOrderMealBalancingSummary(filter: OrderSpecSearchFilter) {
  return apiService.get<OrdersMealBalancingSummary>('orders', 'MealBalancingSummary', undefined, filter, true);
}

function getOrderSpec(orderId: string) {
  return apiService.get<OrderSpecDto>('orders', 'orderspec', [orderId]).then((result: OrderSpecDto) => {
    result.deliveryScheduleDays.activeDays &&
      result.deliveryScheduleDays.activeDays.forEach((day) => {
        day.day = new Date(day.day);
      });
    result.deliveryScheduleDays.pendingDays &&
      result.deliveryScheduleDays.pendingDays.forEach((day) => {
        day.day = new Date(day.day);
      });
    return result;
  });
}

function updateCustomOrderStatus(orderId: string, status: boolean) {
  return apiService.patch('orders', `${orderId}/CustomOrder`, undefined, undefined, { status }, true);
}

function getOrder(orderId: string) {
  return apiService.get<Order>('orders', undefined, [orderId]);
}

function getOrderSummary(orderId: string) {
  return apiService.get<Order>(`orders/${orderId}/summary`);
}

function calculateUpdatedMacros(orderId: string, updatedGoal: string, updatedNutritionPreference: string, updatedPregnancyStatus: string, updatedBreastfeedingStatus: string) {
  return apiService.get<MacrosDto>("orders", undefined, [orderId, 'UpdatedMacros'], { updatedGoal, updatedNutritionPreference, updatedPregnancyStatus, updatedBreastfeedingStatus });
}

function changeOrderStartDate(orderId: string, startDate: Date, paymentContext: PaymentContextDto) {
  return apiService.patch<OrderUpdateResultDto>('orders', `${orderId}/ChangeStartDate`, undefined,
    undefined, { startDate: utilsService.formatDate(startDate), ...paymentContext }, true);
}

function getMinOrderStartDate(orderId: string) {
  return apiService.get<Date>('orders', orderId, ['MinStartDate'], undefined, true);
}

function stageExistingOrder(orderId: string) {
  return apiService.post<OrderStaging>('orders', `${orderId}/Stage`, null, [], null, true)
    .then(order => {
      if (order.deliveryScheduleStaging)
        order.deliveryScheduleStaging.startDate = new Date(order.deliveryScheduleStaging.startDate);

      return order;
    });
}

function getNextDeliverableDate(paymentOption?: PaymentOption, date?: Date, postcodeGroupId?: string,
  selectedDeliveryDaysOfWeek?: DaysOfWeek[]) {
  return apiService.get<DeliverableDateInfo>('orders', 'NextDeliverableDate/info', [],
    {
      effectiveDate: date && utilsService.formatDate(date),
      paymentOption, postcodeGroupId, selectedDeliveryDaysOfWeek
    })
    .then((res: DeliverableDateInfo) => ({
      date: new Date(res.date),
      availableDaysOfWeek: res.availableDaysOfWeek
    }));
}

function clearStagedOrders() {
  return apiService.patch('OrderStaging', 'ClearStagedOrders', null);
}

function addFreeBagToOrder(freeBag: OrderFreeBagRequestDto) {
  return apiService.post('orders', 'FreeBag', freeBag);
}

function setUpPayPalAuthorization(orderRequest: PayPalAuthorizationRequestDto) {
  return apiService.post<PayPalOrderResponseDto>('orders', 'Payment/PayPal', orderRequest);
}

function authorizePayPalOrder(payPalOrderId: string) {
  return apiService.post<PayPalOrderResponseDto>('orders', `Payment/PayPal/Authorize/${payPalOrderId}`, null);
}

function getMealBalancingStatus(status: MealBalancingStatus) {
  switch (status) {
    case MealBalancingStatus.Unknown: return 'Unknown';
    case MealBalancingStatus.Pending: return 'Pending';
    case MealBalancingStatus.Approved: return 'Approved';
    case MealBalancingStatus.AutoApproved: return 'Auto Approved';
    case MealBalancingStatus.ApprovalRequired: return 'Approval Required';
    case MealBalancingStatus.ManualBalancingRequired: return 'Manual Balancing Required';
    case MealBalancingStatus.FailedDueToErrors: return 'Failed Due To Errors';
  }
}

function getCssClassForBalancingStatus(status: MealBalancingStatus) {
  switch (status) {
    case MealBalancingStatus.Pending: return 'btn text-secondary';
    case MealBalancingStatus.ManualBalancingRequired:
    case MealBalancingStatus.FailedDueToErrors: return 'btn text-danger';
    case MealBalancingStatus.Unknown: return 'btn text-black';
    case MealBalancingStatus.ApprovalRequired: return 'btn text-warning'
    case MealBalancingStatus.Approved:
    case MealBalancingStatus.AutoApproved: return 'btn text-success';
  }
}

function getColorCodeForBalancingStatus(status: MealBalancingStatus) {
  switch (status) {
    case MealBalancingStatus.Pending: return '#6c757d';
    case MealBalancingStatus.ManualBalancingRequired:
    case MealBalancingStatus.FailedDueToErrors: return '#DC3545';
    case MealBalancingStatus.Unknown: return '#212529';
    case MealBalancingStatus.ApprovalRequired: return '#856404'
    case MealBalancingStatus.Approved: return '#036E38';
    case MealBalancingStatus.AutoApproved: return '#28A745';
  }
}

function getGuestOrdersCsv(fromDate: Date, toDate: Date) {
  return apiService.download<any>('OrderStaging', 'GuestOrders/Csv', [], {
    fromDate: utilsService.formatDate(fromDate),
    toDate: utilsService.formatDate(toDate)
  });
}

function showOrderEditWizard(orderId: string, successCallback: () => void) {
  orderAction.loadOrderEditWizard(orderId, successCallback)(store.dispatch);
}

function getWeeklyOrders(request?: WeeklyOrdersListRequest) {
  return apiService.get<WeeklyOrderListItemDto[]>('orders', 'WeeklyOrders', [],
    {
      ...request,
      toDate: request && request.toDate && request.toDate.toDateString(),
      fromDate: request && request.fromDate && request.fromDate.toDateString(),
    });
}

function fixStaledMealBalancing(orderId: string) {
  return apiService.patch<number>('orders', `${orderId}/FixStaledMealBalancingRequests`, undefined);
}

function makeWeeklyPayment(orderId: string, makeFullPayment: boolean) {
  return apiService.put<SpotPaymentResult>('orders', 'WeeklyOrders', { makeFullPayment }, [orderId, 'WeeklyPayment'], null, true);
}

function getDefaultPackage(packages?: OrderMetadataItem[]) {
  return packages && packages.filter((val: OrderMetadataItem) => val.code === intl.get(DEFAULT_ORDER_METADATA_PACKAGE_CODE))[0];
}

function getTrialOrders(filter: TrialOrdersSearchFilter) {
  return apiService.get<TrialOrderSearchResultsDto>('Orders', 'Trial', [],
    {
      ...filter,
      fromDate: filter.fromDate && filter.fromDate.toDateString(),
      toDate: filter.toDate && filter.toDate.toDateString(),
    }, true);
}

function getTrialOrdersSummary(fromDate?: Date, toDate?: Date) {
  return apiService.get<TrialOrdersSummaryDto>('Orders', 'Trial/Summary', [], { fromDate: fromDate && fromDate.toDateString(), toDate: toDate && toDate.toDateString() }, true);
}

function downloadTrialOrdersCsv(fromDate?: Date, toDate?: Date) {
  return apiService.download<any>('Orders', 'Trial/Csv', [], { fromDate: fromDate && fromDate.toDateString(), toDate: toDate && toDate.toDateString() });
}

function deleteStagingOrder(id: string) {
  return apiService.delete('OrderStaging', '', [id], undefined, true);
}

function getOrderStagingById(id: string) {
  return apiService.get<OrderStaging>('OrderStaging', '', [id]);
}

function getFlexiOrders(filter: FlexiOrdersSearchFilter) {
  return apiService.get<SearchResultsDto<FlexiOrderDto>>('Orders', 'Flexi', [],
    {
      ...filter,
      fromDate: filter.fromDate && utilsService.formatDate(filter.fromDate),
      toDate: filter.toDate && utilsService.formatDate(filter.toDate),
    }, true);
}

function downloadFlexiOrdersCsv(fromDate?: Date, toDate?: Date) {
  return apiService.download<any>('orders', 'Flexi/Csv', [], { fromDate: fromDate && fromDate.toDateString(), toDate: toDate && toDate.toDateString() });
}

function getFlexiAnalyticsSummary(fromDate: Date, toDate: Date) {
  return apiService.get<FlexiAnalyticsSummaryDto>('Orders', 'Flexi/AnalyticsSummary', [], { fromDate: utilsService.formatDate(fromDate), toDate: utilsService.formatDate(toDate) }, true);
}

function downloadFlexiPaymentsCsv(fromDate?: Date, toDate?: Date) {
  return apiService.download<any>('orders', 'Flexi/PaymentsCsv', [], { fromDate: fromDate && fromDate.toDateString(), toDate: toDate && toDate.toDateString() });
}

function downloadFlexiOrderPauseLogsCsv(fromDate?: Date, toDate?: Date) {
  return apiService.download<any>('orders', 'Flexi/pause-logs/csv', [], { fromDate: fromDate && fromDate.toDateString(), toDate: toDate && toDate.toDateString() });
}

function getOrderPricingSummary(pricingSummaryRequest: OrderPricingSummaryRequestDto) {
  return apiService.post<OrderPricingSummaryDto>('OrderStaging', 'PricingSummary', {
    ...pricingSummaryRequest,
    firstDeliveryDate: pricingSummaryRequest.firstDeliveryDate &&
      utilsService.formatDate(pricingSummaryRequest.firstDeliveryDate)
  });
}

function getMaximumApplicableTokens(bagPriceRequest: MaximumApplicableTokensRequestDto) {
  return apiService.post<number>('OrderStaging', 'MaximumApplicableTokens', {
    ...bagPriceRequest,
    firstDeliveryDate: bagPriceRequest.firstDeliveryDate &&
      utilsService.formatDate(bagPriceRequest.firstDeliveryDate)
  });
}

function getOrderDetailedInfo(orderId?: string) {
  return apiService.get<CurrentPackageSummary>('orders', `${orderId}/DetailedInfo`);
}

function getTooltipTextForNutritionPreference(preferenceCode: string) {
  switch (preferenceCode) {
    case NUTRITION_PREFERENCE_CODE.BALANCED:
      return intl.get('TXT_BALANCED_TOOLTIP');
    case NUTRITION_PREFERENCE_CODE.LOW_CARB:
      return intl.get('TXT_LOW_CARB_TOOLTIP');
    case NUTRITION_PREFERENCE_CODE.PESCITARIAN:
      return intl.get('TXT_PESCATARIAN_TOOLTIP');
    case NUTRITION_PREFERENCE_CODE.OFFICE:
      return intl.get('TXT_OFFICE_NUTRITION_TOOLTIP');
    case NUTRITION_PREFERENCE_CODE.PALEO:
      return intl.get('TXT_PALEO_TOOLTIP');
    case NUTRITION_PREFERENCE_CODE.PLANT_BASED:
      return intl.get('TXT_PLANT_BASED_TOOLTIP');
    case NUTRITION_PREFERENCE_CODE.VEGGIE:
      return intl.get('TXT_VEGGIE_TOOLTIP');
    case NUTRITION_PREFERENCE_CODE.VEGAN:
      return intl.get('TXT_VEGAN_TOOLTIP');
    case NUTRITION_PREFERENCE_CODE.OWN_MACROS:
      return intl.get('TXT_OWN_MACROS_TOOLTIP');
    case NUTRITION_PREFERENCE_CODE.FLEXITARIAN:
      return intl.get('TXT_FLEXITARIAN_TOOLTIP');
  }
}

function getApplicableFFFPackages(packageMetadata: PackageMetadata[], weeklyPaymentPackagesOnly?: boolean, waiverFeeThreshold?: number) {
  let filteredMetadata = packageMetadata && packageMetadata
    .filter((p: PackageMetadata) => !p.packageOrderMetadata.adminOnly && !p.promotional && !p.f45 &&
      (!weeklyPaymentPackagesOnly || p.weeklyPaymentEnabled == weeklyPaymentPackagesOnly) &&
      (waiverFeeThreshold == undefined || waiverFeeThreshold == 0 || p.totalDays % waiverFeeThreshold === 0));

  return filteredMetadata;
}