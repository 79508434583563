import { ClientDashboardTabNames } from '../constants';
import QueryClientKeys from '../constants/query-client-key.constants';
import { fffQueryClient } from '../modules/shared/components/QueryClient/FFFQueryClient';
import { store } from '../store';
import { coreActions } from '../store/core/core.actions';
import { useFoodBoxCustomerPortalStore } from '../store/food-box/customer-portal.store';
import { useFoodBoxOrderFormStore } from '../store/food-box/order-form.store';
import { useOrderEditStore } from '../store/order-edit/order-edit.store';
import { CLEAR_ORDER_FORM_ON_LOGOUT } from '../store/order/order.constants';
import { CLEAR_SUPPLEMENT_INVENTORY_SELECTION } from '../store/supplement/supplement.constants';
import {
  AuthResult, ForgotPasswordRequestResultDto, LoginCredentials, PasswordChangeDto, PasswordResetDto, RefreshToken,
  SocialSignInInfo, TwoFactorSetupInfoDto, TwoFactorSetupResultDto, UserRoleDto
} from '../types/account-dto.types';
import { AuthUser } from '../types/shared-store.types';
import { TenantBasicInfo, TenantConfigurationMap } from '../types/tenant.types';
import { apiService } from "./shared/api.service";
import { sessionService } from "./shared/session.service";
import { tenantService } from './tenant.service';

export const accountService = {
  login,
  logout,
  getUserRoles,
  resetPassword,
  changePassword,
  recoverPassword,
  refreshToken,
  impersonateUser,
  impersonateUserWithPriorityLeadTime,
  stopImpersonation,
  signInWithSocialLogin,
  getAuthResult,
  switchTenant,
  get2faSettings,
  setup2fa,
  verify2faAuthentication,
  createAuthUser,
  reset2fa,
  getRelatedTenants,
  getCurrentActiveTabCode,
  removeUserSpecificQueries
}

function setTenantConfigurations() {
  tenantService.getTenantConfigurations()
    .then((configs: TenantConfigurationMap) => {
      sessionService.setTenantConfiguration(configs);
    })
}

function login(credentials: LoginCredentials) {
  sessionService.clearAuthState();
  return apiService.post<AuthResult>('account', 'login', credentials)
    .then((result) => {
      if (result.succeeded) {
        if (!result.defaultTenant) result.defaultTenant = result.subscribedTenants[0];

        sessionService.setAuthState(result);
        store.dispatch(coreActions.setAuthUser(createAuthUser(result)));
        store.dispatch(coreActions.setCurrentTenant(result.defaultTenant));
        setTenantConfigurations();
      } else if (!result.succeeded && (result.twoFactorSetupRequired || result.twoFactorAuthRequired)) {
        sessionService.setAuthTokens(result);
      }

      return {
        succeeded: result.succeeded,
        errorCode: result.errorCode,
        isLockedOut: result.isLockedOut,
        lockoutDuration: result.lockoutDuration,
        auth: result,
        twoFactorAuthRequired: result.twoFactorAuthRequired,
        twoFactorSetupRequired: result.twoFactorSetupRequired
      };
    });
}

function switchTenant(tenantCode: string) {
  return apiService.post<AuthResult>('account', 'SwitchTenant', undefined, [tenantCode])
    .then((result) => {
      let authState = undefined;
      if (result.succeeded) {
        if (!result.defaultTenant) result.defaultTenant = tenantCode;

        sessionService.setAuthState(result);
        store.dispatch(coreActions.setAuthUser(createAuthUser(result)));
        store.dispatch(coreActions.setCurrentTenant(result.defaultTenant));
        setTenantConfigurations();
      }
      return authState;
    });
}

function getAuthResult(credentials: LoginCredentials) {
  return apiService.post<AuthResult>('account', 'login', { ...credentials, bypass2fa: true });
}

function signInWithSocialLogin(e: SocialSignInInfo) {
  sessionService.clearAuthState();
  return apiService.post<AuthResult>('account', `login/${e.provider}`, { authToken: e.authToken }, undefined, undefined, true)
    .then((result) => {
      let auth = undefined;
      if (result.succeeded) {
        if (!result.defaultTenant) result.defaultTenant = result.subscribedTenants[0];

        sessionService.setAuthState(result);
        store.dispatch(coreActions.setAuthUser(createAuthUser(result)));
        store.dispatch(coreActions.setCurrentTenant(result.defaultTenant));
        setTenantConfigurations();
      } else if (!result.succeeded && (result.twoFactorSetupRequired || result.twoFactorAuthRequired)) {
        sessionService.setAuthTokens(result);
      }

      return {
        succeeded: result.succeeded,
        errorCode: result.errorCode,
        isLockedOut: result.isLockedOut,
        lockoutDuration: result.lockoutDuration,
        auth,
        twoFactorAuthRequired: result.twoFactorAuthRequired,
        twoFactorSetupRequired: result.twoFactorSetupRequired
      };
    });
};

function refreshToken() {
  const authToken = sessionService.getAuthToken();
  const refreshToken = sessionService.getRefreshToken();
  if (authToken && refreshToken) {
    const dto: RefreshToken = {
      authToken,
      refreshToken
    }
    return apiService.post<AuthResult>('account', 'RefreshToken', dto)
      .then((result) => {
        if (result.succeeded) {
          sessionService.setAuthState(result);
          store.dispatch(coreActions.setAuthUser(createAuthUser(result)));
          store.dispatch(coreActions.setCurrentTenant(result.defaultTenant));
          setTenantConfigurations();
        } else {
          sessionService.clearAuthState();
        }
        return result;
      });
  } else throw 'Auth Token Not Available';
}

function logout() {
  let authToken = sessionService.getAuthToken();
  return apiService.post('account', 'logout', { authToken })
    .finally(() => {
      store.dispatch({ type: CLEAR_ORDER_FORM_ON_LOGOUT });
      store.dispatch({ type: CLEAR_SUPPLEMENT_INVENTORY_SELECTION });
      store.dispatch(coreActions.clearAuthUser());
      sessionService.clearAuthState();

      // Clearing store data
      useOrderEditStore.getState().resetAllData();
      useFoodBoxOrderFormStore.getState().resetAllData();
      useFoodBoxCustomerPortalStore.getState().resetAllData();

      removeUserSpecificQueries();

      if (window.hasOwnProperty("HubSpotConversations") && window.hasOwnProperty("_hsq")) {
        //@ts-ignore
        window._hsq.push(['revokeCookieConsent']);
        //@ts-ignore
        window.HubSpotConversations.widget.remove();
      }
    });
}

function getUserRoles() {
  return apiService.get<UserRoleDto[]>('account', 'roles');
}

function resetPassword(data: PasswordResetDto) {
  return apiService.patch('account', 'resetpassword', data);
}

function changePassword(data: PasswordChangeDto) {
  return apiService.put<AuthResult>('account', 'changepassword', data)
    .then((result) => {
      if (result.succeeded) {
        sessionService.setAuthState(result);
      }
      return { succeeded: result.succeeded, errorCode: result.errorCode };
    });
}

function recoverPassword(email: string) {
  return apiService.patch<ForgotPasswordRequestResultDto>('account', 'recoverpassword', { email }, []);
}

function impersonateUser(impersonatingUserId: string) {
  return apiService.get<AuthResult>('account', 'Impersonate', [impersonatingUserId])
    .then(result => {
      if (result.succeeded) {
        store.dispatch(coreActions.setAuthUser(createAuthUser(result)));
        sessionService.setAuthState(result);
      }

      return result;
    })
}

function impersonateUserWithPriorityLeadTime(impersonatingUserId: string) {
  return apiService.get<AuthResult>('account', 'Impersonate', [impersonatingUserId, 'WithPriorityLeadTime'])
    .then(result => {
      if (result.succeeded) {
        store.dispatch(coreActions.setAuthUser(createAuthUser(result)));
        sessionService.setAuthState(result);
      }

      return result;
    })
}

function stopImpersonation() {
  let authToken = sessionService.getAuthToken();
  let data = { authToken };

  return apiService.post<AuthResult>('account', 'ExitImpersonation', data)
    .then(result => {
      if (result.succeeded) {
        store.dispatch(coreActions.setAuthUser(createAuthUser(result)));
        sessionService.setAuthState(result);
      }

      return result;
    })
}

function createAuthUser(authResult: AuthResult) {
  var authState: AuthUser = {
    permissions: authResult.permissions,
    user: authResult.user,
    isImpersonating: authResult.isImpersonating || false,
    defaultTenant: authResult.defaultTenant,
    subscribedTenants: authResult.subscribedTenants,
  };
  return authState;
}

function get2faSettings() {
  return apiService.get<TwoFactorSetupInfoDto>('account', '2fasettings');
}

function setup2fa(tokenCode: string) {
  return apiService.post<TwoFactorSetupResultDto>('account', 'setup2fa', undefined, undefined, { tokenCode })
    .then(result => {
      if (result.succeeded) {
        sessionService.clearAuthState();
      }
      return result;
    });
}

function verify2faAuthentication(tokenCode: string) {
  return apiService.post<AuthResult>('account', 'verify2fa', undefined, undefined, { tokenCode })
    .then(result => {
      let auth = undefined;
      if (result.succeeded) {
        store.dispatch(coreActions.setAuthUser(createAuthUser(result)));
        sessionService.setAuthState(result);
      }
      return {
        succeeded: result.succeeded,
        errorCode: result.errorCode,
        auth,
        twoFactorAuthRequired: result.twoFactorAuthRequired,
        twoFactorSetupRequired: result.twoFactorSetupRequired
      };
    });
}

function reset2fa(userId: string) {
  return apiService.patch('account', `${userId}/reset2fa`, [userId]);
}

function getRelatedTenants(userId?: string) {
  return apiService.get<TenantBasicInfo[]>('account', "RelatedTenants", undefined, { userId });
}

function getCurrentActiveTabCode(newUrl: string, tabKey: string) {
  let currentActiveTabCode;
  if (newUrl.includes('?')) {
    currentActiveTabCode = newUrl.split('?tab=')[1].toUpperCase();
    if (ClientDashboardTabNames.hasOwnProperty(currentActiveTabCode)) {
      currentActiveTabCode = ClientDashboardTabNames[currentActiveTabCode];
    }
  } else {
    currentActiveTabCode = tabKey;
  }
  return currentActiveTabCode;
}

function removeUserSpecificQueries() {
  fffQueryClient.removeQueries(QueryClientKeys.Delivery.UserDeliveryInfos);
  fffQueryClient.removeQueries(QueryClientKeys.UserCurrentOrder);
  fffQueryClient.removeQueries(QueryClientKeys.Delivery.MinActionableDateByOrderId);
  fffQueryClient.removeQueries(QueryClientKeys.Customer.CurrentUserCurrentOrderBasicInfo);
  fffQueryClient.removeQueries(QueryClientKeys.OrderDetailedInfo);
  fffQueryClient.removeQueries(QueryClientKeys.FoodBox.FoodBoxEarliestEffectiveDate);
}